import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import PropsTypes from "prop-types";

// helpers
import useQueryParams from "../../../hooks/useQueryParams";


function FilterByPrice({ onClose }) {
  const [{ priceMin, priceMax }, setQueryParams] = useQueryParams();

  const [priceFilters, setPriceFilters] = useState({ priceMin, priceMax });

  useEffect(() => setPriceFilters({ priceMin, priceMax }), [priceMin, priceMax]);

  const hasError = priceFilters.priceMax &&
  priceFilters.priceMax.length && (+priceFilters.priceMin > +priceFilters.priceMax);

  const handlePriceChange = (key, price) => {
    setPriceFilters({
      ...priceFilters,
      [key]: price,
    });
  }

  const applyFilter = () => {
    setQueryParams({
      ...priceFilters,
      page: 1
    });

    onClose && onClose();
  };

  return (
    <div className="mobile-filter-drawer__filter-container mobile-filter-drawer__filter-apply-container">
      <div className="mobile-filter-drawer__filter-inputs-grid">
        <div className="mobile-filter-drawer__filter-input-wrapper">
          <span className="mobile-filter-drawer__filter-input-label">£</span>
          <Input
            min={0}
            placeholder="Price From"
            className="mobile-filter-drawer__filter-input"
            type="number"
            data-testid="filterInputPriceFrom"
            value={priceFilters.priceMin}
            onChange={(e) => handlePriceChange("priceMin", e.target.value)}
          />
        </div>
        <div className="mobile-filter-drawer__filter-input-wrapper">
          <span className="mobile-filter-drawer__filter-input-label">£</span>
          <Input
            min={0}
            placeholder="Price To"
            className="mobile-filter-drawer__filter-input"
            type="number"
            data-testid="filterInputPriceTo"
            value={priceFilters.priceMax}
            onChange={(e) => handlePriceChange("priceMax", e.target.value)}
          />
        </div>
        {
          hasError && <div className="mobile-filter-drawer__filter-error" data-testid="filterError">
          The starting price cannot be more than the Final price
          </div>
        }
      </div>
      <div className="mobile-filter-drawer__filter-apply-container">
        <Button onClick={applyFilter} disabled={hasError} data-testid="applyFiltersButton">
          Apply Search
        </Button>
      </div>
    </div>
  );
}

FilterByPrice.propTypes = {
  onClose: PropsTypes.func,
};

export default FilterByPrice;
