import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Menu } from "antd";

//components
import DrawerWrapper from "../../shared/DrawerWrapper";
import FilterByPrice from "./FilterByPrice";

//assets
import ChevronIcon from "../../Icons/ChevronIcon";


function BrowseToNavigationDrawer ({
  isOpened,
  onClose,
  categoriesList = [],
  categoryRelatedMaterials = [],
  categoryRelatedOrigins = [],
  categoryRelatedPeriods = [],
  similarSearches = [],
  similarSearchesTitle,
}) {
  const [activeNavigationTab, setActiveNavigationTab] = useState(null);

  const history = useHistory();

  const mainNavigation = [
    { name: "Price", key: "filterByPrice", component: <FilterByPrice onClose={onClose} /> },
    { name: "Category", key: "category", navigationLinksList: categoriesList },
    { name: "Periods", key: "periods", navigationLinksList: categoryRelatedPeriods },
    { name: "Materials", key: "materials", navigationLinksList: categoryRelatedMaterials },
    { name: "Origins", key: "origins", navigationLinksList: categoryRelatedOrigins },
    { name: similarSearchesTitle, key: "similarSearches", navigationLinksList: similarSearches },
  ];

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setActiveNavigationTab(null);
  }

  const filteredMainNavigation = mainNavigation
    .filter(({ navigationLinksList, component }) => component || !!navigationLinksList.length);


  const renderNavigation = () => (
    <>
      <div className="mobile-filter-drawer__header">
        Filter by
      </div>
      <div className="mobile-filter-drawer__navigation">
        {
          filteredMainNavigation.map((navigationItem, index) => (
            <div
              data-testid={navigationItem.key}
              key={`${navigationItem.key}-${index}`}
              className="mobile-filter-drawer__main-nav-link"
              onClick={() => setActiveNavigationTab(navigationItem)}
            >
              <span className="mobile-filter-drawer__main-nav-label">{ navigationItem.name }</span>
              <ChevronIcon className="mobile-filter-drawer__main-nav-icon" direction="right"/>
            </div>
          ))
        }
      </div>
    </>
  );


  return (
    <DrawerWrapper
      closeDrawer={handleClose}
      isDrawerOpened={isOpened}
      className={"mobile-filter-drawer"}
    >
      { activeNavigationTab
        ? (
          <>
            <div className="mobile-filter-drawer__header mobile-filter-drawer__header_grid">
              <ChevronIcon
                className="mobile-filter-drawer__main-nav-icon_left"
                onClick={() => setActiveNavigationTab(null)}
                direction="left"
                data-testid="drawer-go-back"
              />
              <span>{ activeNavigationTab.name }</span>
            </div>
            <div className="mobile-filter-drawer__body">
              {
                activeNavigationTab.component
                  ? activeNavigationTab.component
                  : (
                    <Menu>
                      {
                        activeNavigationTab.navigationLinksList.map(({ title, itemsCount, url }, index) => (
                          <Menu.Item
                            key={`navigation-${index}`}
                            onClick={() => history.push({ pathname: `/${url}` })}
                          >
                            <span className="mobile-filter-drawer__link-label">{title}</span>
                            <span>{` (${itemsCount})`}</span>
                          </Menu.Item>
                        ))
                      }
                    </Menu>
                  )
              }
            </div>
          </>
        )
        : renderNavigation() }
    </DrawerWrapper>
  );
}

BrowseToNavigationDrawer.propTypes = {
  isOpened: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  categoriesList: PropTypes.array,
  categoryRelatedMaterials: PropTypes.array,
  categoryRelatedOrigins: PropTypes.array,
  categoryRelatedPeriods: PropTypes.array,
  similarSearches: PropTypes.array,
  similarSearchesTitle: PropTypes.string,
};

export default BrowseToNavigationDrawer;
