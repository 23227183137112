import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";

const DrawerWrapper = ({
  isDrawerOpened,
  closeDrawer,
  children,
  className,
}) => {

  return (
    <Drawer
      open={isDrawerOpened}
      className={`dropdown-drawer ${className}`}
      onClose={closeDrawer}
      placement="bottom"
      closable={false}
      height={"auto"}
    >
      <div className="dropdown-drawer__content">
        { children }
      </div>
    </Drawer>
  )
};

DrawerWrapper.propTypes = {
  isDrawerOpened: PropTypes.bool.isRequired,
  closeDrawer: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default DrawerWrapper;
